import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddProfile from "./AddProfile";
import ViewProfile from "./ViewProfile";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { toast } from "react-toastify";
import { getUserRoleID } from "./../../Utils/getSessionstorageItems";

const Profile = () => {
  const [view, setView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("global");

  const handleView = () => setView((prevView) => !prevView);

  const userID = sessionStorage.getItem("user_id");
  const [user, setUser] = useState(null);
  const [teams, setTeams] = useState([]);
  console.log("user", user);
  const getUserDataFromAPI = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });
      const user = response?.data?.data;
      if (user) {
        setUser(user);
      }
    } catch (error) {
      toast.error(t(error?.response?.data?.errors[0] || error?.message));
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    // const getUserDataFromAPI = async () => {
    //   setLoading(true);
    //   try {
    //     const response = await axios.get(`${API_BASE_URL}/users/${userID}`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    //       },
    //     });
    //     const user = response?.data?.data;
    //     if (user) {
    //       setUser(user);
    //     }
    //   } catch (error) {
    //     toast.error(t(error.response?.data?.errors[0] || error.message));
    //   } finally {
    //     setLoading(false);
    //   }
    // };
    const getTeams = async () => {
      const token = sessionStorage.getItem("token");
      try {
        setLoading(true);

        const response = await axios.get(`${API_BASE_URL}/teams`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const filterredActiveTeams = response?.data?.data?.filter(
            (team) => team.status === "active"
          );
          setLoading(false);

          if (getUserRoleID() === 3) {
            // setAllTeams(response?.data?.data);
            const teams = response.data.data.filter(
              (team) => team?.enterprise?.id === response?.data?.enterprise?.id
            );
            setTeams(teams);
          } else {
            // setAllTeams(response?.data?.data);
            const teams = response.data.data.filter(
              (team) => team?.enterprise?.id === response?.data?.enterprise?.id
            );
            setTeams(teams);
          }
        }
      } catch (error) {
        toast.error(t(error.response?.data?.errors[0] || error?.message));
        // console.log("error message", error);
      } finally {
        setLoading(false);
      }
    };
    getUserDataFromAPI();
    getTeams();
  }, [userID, view]);

  return (
    <div className="visiting-card mt-3">
      <Container fluid>
        <Row>
          <Col md={6} className="visiting-card-heading">
            <h3>{t("profile.visitingCard")}</h3>
          </Col>
          <Col md={6} className="visiting-card-header">
            {view ? (
              <div className="view-btn" onClick={handleView}>
                <img src="/Assets/profile_view_icon.svg" alt="" />
                <h5>{t("profile.viewVisitingCard")}</h5>
              </div>
            ) : (
              <div className="view-btn" onClick={handleView}>
                <img src="/Assets/profile_close_icon.svg" alt="" />
                <h5>{t("profile.exitView")}</h5>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {view ? (
        <AddProfile
          user={user}
          teams={teams}
          isLoading={loading}
          refreshUserData={getUserDataFromAPI}
        />
      ) : (
        <ViewProfile user={user} />
      )}
    </div>
  );
};

export default Profile;
